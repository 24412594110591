.bg_pubAppointment{
    background-image: url('../../imgz/PubAppointment/ToothPaste.jpg');
   
    background-position-y: 40px;
}
.bg_pubAppointment , .bg_createAccount{
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right;
    
}
.bg_createAccount{
    background-image: url('../../imgz/PubAppointment/CreateAccount.jpg');
    background-position-y: 80px;

}
.short_desc{
    text-overflow: ellipsis;
    overflow: hidden;
  

    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}
.prodCard{
    box-shadow: 0px 0px 5px blue;
    border: 1px solid #fff;
}
.prodDetails_desc{
    line-height: 2em;
    text-align: justify;
}
.ellipsis {
    text-overflow: ellipsis; /* enables ellipsis */
    white-space: nowrap; /* keeps the text in a single line */
    overflow: hidden; /* keeps the element from overflowing its parent */
  }

.card-text{
    
    height: 50px;
    font-size: 14px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* start showing ellipsis when 2nd line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
}
.btn.prodCardBtn{
    font-size: 10px;;
}
h6{
    font-size: 12px;
    
}